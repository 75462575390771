import './SettingsAuditTrailsContent.less'

import { Button } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  AuditTrailType,
  AuditTrailsSorter,
  SorterOrder,
  SystemAuditTrail,
  exportGlobalAuditTrails,
  fetchSystemAuditTrails,
  fetchUsers
} from '../../../requests'
import { UserContext } from '../../auth'
import { DatacIcon, DatacMessage, DatacOption, DatacTitle } from '../../common'
import { AuditTrailsTable } from '../../shared/AuditTrails'
import { SettingsLayout } from '../SettingsLayout'

export const SettingsAuditTrailsContent: React.FC = () => {
  const intlAuditTrails = useScopedIntl('system.audit_trails')
  const intl = useScopedIntl('')
  const [trails, setTrails] = useState<SystemAuditTrail[]>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [trailsTotal, setTrailsTotal] = useState(0)
  const [usersAvailableInFilter, setUsersAvailableInFilter] = useState<DatacOption[]>([])
  const { user } = useContext(UserContext)
  const [isExporting, setIsExporting] = useState(false)
  const cancelExportRef = useRef<() => void>(() => null)

  const fetchAuditTrailsPage = (
    page: number,
    sorter: AuditTrailsSorter,
    pageSize: number,
    filters: Record<string, string[]>
  ) => {
    return fetchSystemAuditTrails(
      {
        options: {
          limit: pageSize,
          offset: page * pageSize,
          sorter,
          filters: {
            user: filters?.user,
            action: filters?.action,
            event: filters?.event
          }
        }
      },
      {
        onSuccess: ({ auditTrails, allAuditTrailsCount }) => {
          setTrails(auditTrails)
          setTrailsTotal(allAuditTrailsCount)
          setCurrentPage(page + 1)
        },
        onRequestError: () => {
          DatacMessage.error(intlAuditTrails('error.fetch_trails.title'), intlAuditTrails('error.fetch_trails.body'))
        }
      }
    )
  }

  useEffect(() => {
    return () => {
      cancelExportRef.current()
    }
  }, [])

  const fetchUsersAvailableInFilter = (search: string) => {
    fetchUsers(
      { options: { sorter: { field: 'name', order: SorterOrder.Ascend }, search } },
      {
        onSuccess: ({ users }) =>
          setUsersAvailableInFilter(
            users.map(user => ({
              value: user.id,
              label: user.name
            }))
          ),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onExportClick = () => {
    setIsExporting(true)
    const cancel = exportGlobalAuditTrails({
      onSuccess: () => {
        setIsExporting(false)
      },
      onRequestError: code => {
        setIsExporting(false)
        DatacMessage.genericError(intl, code)
      }
    })

    cancelExportRef.current = cancel
  }

  return (
    <SettingsLayout>
      <div className="system-audit-trails-content">
        <div className="audit-trails">
          <div className="audit-trails__header">
            <DatacTitle type="h2">{intlAuditTrails('title')}</DatacTitle>
            {user.canDo(AclFeature.AuditTrails)(AclAction.Export) && (
              <Button
                className="audit-trails__export-button"
                type="primary"
                size="large"
                onClick={onExportClick}
                loading={isExporting}
              >
                <DatacIcon type="white" name="download" size="big" />
                {intlAuditTrails('export_data')}
              </Button>
            )}
          </div>
          <AuditTrailsTable
            trailsType={AuditTrailType.System}
            trails={trails}
            currentPage={currentPage}
            totalTrails={trailsTotal}
            fetchTrailsPage={fetchAuditTrailsPage}
            usersAvailableInFilter={usersAvailableInFilter}
            getUsersAvailableInFilter={fetchUsersAvailableInFilter}
          />
        </div>
      </div>
    </SettingsLayout>
  )
}
